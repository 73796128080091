import React, { useMemo } from 'react';
import { BlogPostAuthor, BlogPostAuthorName } from 'types/post';
import Eliska from 'images/memojis/eliska_static.gif';
import AviationExamIcon from 'images/favicon.png';
import { Center, Group, Text, createStyles } from '@aviationexam/core';

const useStyles = createStyles((theme, { size }: { size: number }) => ({
  avatar: {
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    borderRadius: '9999px',
    width: size,
    height: size,
    '& img': {
      maxWidth: '150%',
    },
  },
}));

export interface BlogPostAuthorThumbnailProps {
  author: BlogPostAuthor;
  date?: string;
  size?: 'small' | 'large';
}

export function BlogPostAuthorThumbnail({ author, date, size }: BlogPostAuthorThumbnailProps) {
  const { classes } = useStyles({ size: size === 'small' ? 40 : 50 });
  const authorName = BlogPostAuthorName[author] || 'N/A';
  const formattedDate = useMemo(() => {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    return d.toLocaleDateString();
  }, [date]);
  return (
    <Group>
      <Center className={classes.avatar}>
        <img src={author === 'eliska' ? Eliska : AviationExamIcon} alt={authorName} />
      </Center>

      <div>
        <Text weight={600}>{authorName}</Text>
        {formattedDate && (
          <Text size="sm" color="dimmed">
            {formattedDate}
          </Text>
        )}
      </div>
    </Group>
  );
}
