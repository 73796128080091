import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  title: {
    textShadow:
      'rgb(11 46 115 / 3%) 0px 1.13458px 0.996212px, rgb(11 46 115 / 4%) 0px 2.72654px 2.39404px, rgb(11 46 115 / 6%) 0px 5.13384px 4.50776px, rgb(11 46 115 / 7%) 0px 9.15789px 8.04107px, rgb(11 46 115 / 8%) 0px 17.1288px 15.0399px, rgb(11 46 115 / 11%) 0px 41px 36px',
    color: theme.colors.gray[8],
    fontWeight: 300,
    fontSize: 48,
  },
  container: {
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.lg,
  },
}));
