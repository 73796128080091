import React from 'react';
import { Link } from 'gatsby';
import { BLOG_PAGE } from 'config/routes';
import { Locale, usePublicTranslation, useTranslatedRoute } from 'features/localization';
import useStyles from './BlogCategoryNav.styles';

interface BlogCategoryNavProps {
  categories: Array<{ categoryKey: string; categoryLabel: string }>;
  category?: string;
  locale: string;
}

export function BlogCategoryNav({ categories, category, locale }: BlogCategoryNavProps) {
  const { t } = usePublicTranslation();
  const { classes, cx } = useStyles();
  const route = useTranslatedRoute(locale as Locale);

  return (
    <nav className={classes.categoryNav}>
      <Link
        to={route(BLOG_PAGE)}
        className={cx(classes.categoryLink, {
          [classes.categoryLinkActive]: !category,
          [classes.categoryLinkInactive]: Boolean(category),
        })}
      >
        {t('blogPage.heading')}
      </Link>
      {categories.map(c => (
        <Link
          key={c.categoryKey}
          to={`${route(BLOG_PAGE)}/${c.categoryKey}`}
          className={cx(classes.categoryLink, {
            [classes.categoryLinkActive]: category === c.categoryKey,
            [classes.categoryLinkInactive]: category !== c.categoryKey,
          })}
        >
          {c.categoryLabel}
        </Link>
      ))}
    </nav>
  );
}

export default BlogCategoryNav;
