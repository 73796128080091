import { ImageDataLike } from 'gatsby-plugin-image';

export type BlogPostAuthor = 'eliska' | 'aviationexam';
export enum BlogPostAuthorName {
  'eliska' = 'Eliška',
  'aviationexam' = 'Aviation Exam',
}
export type PostLocale = 'en' | 'de';

export interface LocalizedPostTag {
  /** Used as URL */
  tagKey: string;
  /** Used for displaying */
  tagLabel: string;
}

export interface PostNode {
  id: string;
  parent: {
    body: string;
  };
  frontmatter: {
    templateKey: string;
    postTitle: {
      en: string;
      de: string;
    };
    postDescription: {
      en: string;
      de: string;
    };
    author: BlogPostAuthor;
    date: string;
    urls: {
      en: string;
      de?: string;
    };
    locale: PostLocale;
    featuredImage: ImageDataLike;
    /**
     * @references Category ID
     */
    category: string;
    tags: LocalizedPostTag[];
  };
}
