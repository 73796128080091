import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { Text, Title, createStyles } from '@aviationexam/core';
import { BlogPostAuthor } from 'types/post';
import { BlogPostAuthorThumbnail } from './BlogPostAuthorThumbnail';

const useStyles = createStyles((theme, { featured }: { featured: boolean }) => ({
  thumbnail: {
    display: 'flex',
    flexWrap: 'wrap',
    textDecoration: 'none',
    height: '100%',
    boxShadow: theme.shadows.lg,
    border: `1px solid ${theme.colors.gray[1]}`,
  },
  imageWrapper: {
    ...(featured && {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        width: '50%',
      },
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        width: '65%',
      },
    }),
    ...(!featured && {
      width: '100%',
    }),
  },
  description: {
    padding: theme.spacing.lg,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...(featured && {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        width: '50%',
      },
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        width: '35%',
      },
    }),
    ...(!featured && {
      width: '100%',
    }),
  },
  title: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    color: theme.colors.gray[8],
  },
  author: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: [theme.spacing.xs, 0].map(p => `${p}px`).join(' '),
  },
}));

export interface BlogPostThumbnailProps {
  title: string;
  author: BlogPostAuthor;
  date: string;
  route: string;
  description: string;
  locale: 'en' | 'de';
  featuredImage: ImageDataLike;
  featured?: boolean;
}

export function BlogPostThumbnail({
  featuredImage,
  title,
  description,
  locale,
  route,
  author,
  date,
  featured = false,
}: BlogPostThumbnailProps) {
  const { classes } = useStyles({ featured });
  const image = getImage(featuredImage);
  return (
    <Link className={classes.thumbnail} to={route} hrefLang={locale}>
      <div className={classes.imageWrapper}>
        <GatsbyImage image={image} alt={title} />
      </div>
      <div className={classes.description}>
        <div>
          <Title order={4} className={classes.title}>
            {title}
          </Title>
          <Text component="p" color="dimmed">
            {description}
          </Text>
        </div>
        <div className={classes.author}>
          <BlogPostAuthorThumbnail author={author} date={date} />
        </div>
      </div>
    </Link>
  );
}
